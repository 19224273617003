import { useRoutes } from 'react-router-dom';
import './App.css';
import { userRoutes } from './routes';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';


function App() {
  const userContent = useRoutes(userRoutes);
  return (
    <ThemeProvider theme={theme}>
      {userContent}
    </ThemeProvider>
  );
}

export default App;
