import { Suspense, lazy } from 'react';
import LoadingScreen from './components/loading/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const LandingPage = Loadable(lazy(() => import('./pages/LandingPage')));
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage')));
const ContactPage = Loadable(lazy(() => import('./pages/ContactPage')));
const ServicesPage = Loadable(lazy(() => import('./pages/ServicesPage')));
const GalleryOptions = Loadable(lazy(() => import('./pages/GalleryOptions')));

export const userRoutes = [
  { path: '/', element: <LandingPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'services', element: <LoginPage /> },
  { path: 'services/:serviceId', element: <ServicesPage /> },
  { path: 'contact', element: <ContactPage /> },
  { path: 'img-gallery', element: <GalleryOptions /> },
  {
    // path: '/*', element: <NotFoundPage />
  }
];